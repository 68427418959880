<template>
    <div class="bg-gray-900">
        <main>
            <!-- Hero section -->
            <div class="relative isolate overflow-hidden">
                <svg class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
                    <defs>
                        <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y="-1" class="overflow-visible fill-gray-800/20">
                        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
                    </svg>
                    <rect width="100%" height="100%" stroke-width="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
                </svg>
                <div class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]" aria-hidden="true">
                    <div class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
                </div>
                <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-40">
                    <div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                        <img class="h-28" src="/assets/mcs_eagle.webp" alt="Your Company" />
                        <h1 class="mt-5 text-4xl font-bold tracking-tight text-white sm:text-6xl" data-aos="fade-right" data-aos-delay="300">
                            {{ $t('hero.title') }}</h1>
                        <p class="mt-6 text-lg leading-8 text-gray-300" data-aos="fade-right" data-aos-delay="300">{{ $t('hero.description') }}</p>
                        <div class="mt-10 flex items-center gap-x-6">
                            <NuxtLink :to="localePath('/contact')" class="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400">
                                {{ $t('contact-us') }}</NuxtLink>
                            <a href="/pdf/MCS_LLC_DESK_EN.pdf" class="text-sm font-semibold leading-6 text-white">{{ $t('download') }} <span aria-hidden="true">→</span></a>
                        </div>
                    </div>
                    <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                        <div class="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                            <img src="/assets/mcs.webp" alt="App screenshot" width="2432" height="1442" class="w-[76rem] animate-bounceY" data-aos="fade-left" data-aos-delay="600"/>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Feature section -->
            <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-28 lg:px-8">
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-base font-semibold leading-7 text-blue-400">{{ $t('feature.title.security') }}</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{{ $t('feature.security.description-first') }}</p>
                    <p class="mt-6 text-lg leading-8 text-gray-300">{{ $t('feature.security.description-second') }}</p>
                </div>
                <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                    <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        <div v-for="(feature, index) in primaryFeatures" :key="feature.name" class="flex flex-col" data-aos="fade-up" :data-aos-delay="300 * index">
                            <dt class="text-base font-semibold leading-7 text-white">
                                <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500">
                                    <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                {{ feature.name }}
                            </dt>
                            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                                <p class="flex-auto">{{ feature.description }}</p>
                                <p class="mt-6">
                                    <NuxtLink :to="localePath('/contact')" class="text-sm font-semibold leading-6 text-blue-400">
                                        {{ $t('contact-us') }} <span aria-hidden="true">→</span></NuxtLink>
                                </p>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <!-- Feature section -->
            <div class="mt-32 sm:mt-56">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="mx-auto max-w-2xl sm:text-center">
                        <h2 class="text-base font-semibold leading-7 text-blue-400">{{ $t('feature.experience.title') }}</h2>
                        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{{ $t('feature.experience.description-first') }}</p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">{{ $t('feature.experience.description-second') }}</p>
                    </div>
                </div>
                <div class="relative overflow-hidden pt-16">
                    <div class="mx-auto max-w-7xl px-6 lg:px-8">
                        <img src="/assets/mcs-smart-home.webp" class="w-1/2 mx-auto rounded-xl" data-aos="zoom-in" data-aos-delay="300"/>
                        <div class="relative" aria-hidden="true">
                            <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
                        </div>
                    </div>
                </div>
                <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
                    <dl class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                        <div v-for="feature in secondaryFeatures" :key="feature.name" class="relative pl-9">
                            <dt class="inline font-semibold text-white">
                                <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-blue-500" aria-hidden="true" />
                                {{ feature.name }}
                            </dt>
                            {{ ' ' }}
                            <dd class="inline">{{ feature.description }}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <!-- Stats -->
            <div class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
                <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                    <h2 class="text-base font-semibold leading-8 text-blue-400">{{ $t('stats.title') }}</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{{ $t('stats.description-first') }} &nbsp;{{ $t('stats.description-second') }}</p>
                    <p class="mt-6 text-lg leading-8 text-gray-300">{{ $t('stats.description-third') }}</p>
                </div>
                <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                    <div v-for="(stat, index) in stats" :key="stat.id" class="flex flex-col gap-y-3 border-l border-white/10 pl-6" data-aos="fade-right" :data-aos-delay="300 * index">
                        <dt class="text-sm leading-6">{{ stat.name }}</dt>
                        <dd class="order-first text-3xl font-semibold tracking-tight">{{ stat.value }}</dd>
                    </div>
                </dl>
            </div>

            <!-- CTA section -->
            <div class="relative isolate mt-32 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
                <svg class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
                    <defs>
                        <pattern id="1d4240dd-898f-445f-932d-e2872fd12de3" width="200" height="200" x="50%" y="0" patternUnits="userSpaceOnUse">
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y="0" class="overflow-visible fill-gray-800/20">
                        <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
                    </svg>
                    <rect width="100%" height="100%" stroke-width="0" fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
                </svg>
                <div class="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
                    <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
                </div>
                <div class="mx-auto max-w-2xl text-center">
                    <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">{{ $t('cta.title') }}<br />{{ $t('contact-us') }}</h2>
                    <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">{{ $t('cta.description') }}</p>
                    <div class="mt-10 flex items-center justify-center gap-x-6">
                        <NuxtLink :to="localePath('/contact')" class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                            {{ $t('contact-us') }}</NuxtLink>
                        <a href="/pdf/MCS_LLC_DESK_EN.pdf" class="text-sm font-semibold leading-6 text-white">{{ $t('download') }} <span aria-hidden="true">→</span></a>
                    </div>
                </div>
            </div>
        </main>

        <!-- Footer -->
        <footer aria-labelledby="footer-heading" class="relative">
            <h2 id="footer-heading" class="sr-only">{{ $t('footer.title') }}</h2>
            <div class="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
                <div class="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
                    <div class="flex space-x-6 md:order-2">
                        <NuxtLink v-for="item in footerNavigation.social" :key="item.name" :href="item.href" target="_blank" class="text-gray-500 hover:text-gray-400">
                            <span class="sr-only">{{ item.name }}</span>
                            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </NuxtLink>
                    </div>
                    <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">&copy; {{ currentYear }} {{ $t('company.name') }}, Inc. All rights reserved.</p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup>
import { defineComponent, h } from 'vue'
import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
} from '@heroicons/vue/20/solid'
import { ShieldCheckIcon, VideoCameraIcon, HomeModernIcon } from '@heroicons/vue/24/outline'

const currentYear = new Date().getFullYear();

const { t } = useI18n();

const primaryFeatures = [
    {
        name: t('primaryFeatures.name.first'),
        description: t('primaryFeatures.description.first'),
        icon: ShieldCheckIcon,
    },
    {
        name: t('primaryFeatures.name.second'),
        description: t('primaryFeatures.description.second'),
        icon: VideoCameraIcon,
    },
    {
        name: t('primaryFeatures.name.third'),
        description: t('primaryFeatures.description.third'),
        icon: HomeModernIcon,
    },
]
const secondaryFeatures = [
    {
        name: t('secondaryFeatures.name.first'),
        description: t('secondaryFeatures.description.first'),
        icon: CloudArrowUpIcon,
    },
    {
        name: t('secondaryFeatures.name.second'),
        description: t('secondaryFeatures.description.second'),
        icon: LockClosedIcon,
    },
    {
        name: t('secondaryFeatures.name.third'),
        description: t('secondaryFeatures.description.third'),
        icon: ArrowPathIcon,
    },
    {
        name: t('secondaryFeatures.name.fourth'),
        description: t('secondaryFeatures.description.fourth'),
        icon: FingerPrintIcon,
    },
    {
        name: t('secondaryFeatures.name.fifth'),
        description: t('secondaryFeatures.description.fifth'),
        icon: Cog6ToothIcon,
    },
    {
        name: t('secondaryFeatures.name.sixth'),
        description: t('secondaryFeatures.description.sixth'),
        icon: ServerIcon,
    },
]
const stats = [
    { id: 1, name: t('stats.name.first'), value: t('stats.value.first') },
    { id: 2, name: t('stats.name.second'), value: t('stats.value.second') },
    { id: 3, name: t('stats.name.third'), value: t('stats.value.third') },
    { id: 4, name: t('stats.name.fourth'), value: t('stats.value.fourth') },
]
const footerNavigation = {
    social: [
        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/company/mcs-llc-us',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            d: 'M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z',
                        }),
                    ]),
            }),
        },
        {
            name: 'What\'s App',
            href: 'https://wa.me/17867075981',
            icon: defineComponent({
                render: () =>
                    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
    ],
}
</script>
